export default {
  title: 'Matches',

  labels: {
    team1: 'Team 1',
    team2: 'Team 2'
  },

  table: {
    header: {
      row: 'Row',
      name: 'Player Name',
      winner: 'Winner',
      date: 'Date',
      deleteAction: 'Delete',
      restoreAction: 'Restore'
    }
  },

  notifications: {
    insert: {
      success: 'Match successfully created',
      error: 'Insert match has error'
    },

    edit: {
      success: 'Match successfully updated',
      error: 'Delete match has error'
    },

    delete: {
      success: 'Match successfully deleted',
      error: 'Delete match has error'
    },

    restore: {
      success: 'Match successfully restored',
      error: 'Restore match has error'
    },

    parseError: {
      score: 'Scores of match is not valid',
      teamLength: 'Team members is not completed',
      playerExist: 'Player is exist in team'
    }
  },

  confirmations: {
    delete: {
      title: 'Delete match confirmation',
      body: 'Are you sure about delete this match?'
    }
  },

  list: {
    title: 'Matches'
  },

  trash: {
    title: 'Removed Matches'
  },

  profile: {
    title: 'Match Profile'
  },

  insert: {
    title: 'Add Match'
  },

  edit: {
    title: 'Edit Match'
  },

  logs: {
    title: 'Match logs'
  }
}