export default {
  regex: {
    utf8: /^[\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC ]{3,}$/,

    percent: /^([0-9۰-۹٠-٩]{1,2}|100|0)$/,

    OTPCode: /^[0-9۰-۹٠-٩]{5}$/,

    name: /^[\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC ]{3,}$/,

    // user
    user: {
      name: /^[\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC ]{3,}$/,
      lastName: /^[\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC ]{3,}$/,
      phoneNumber: /^09[0-9۰-۹٠-٩]{9}$/,
      email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    },

    // clubs
    club: {
      subdomain: /^[a-zA-Z][A-Za-z0-9_]*$/
    },

    // sport
    sports: {
      slug: /^[a-zA-Z][A-Za-z0-9_]*$/
    },

    //message
    messageKey: /^([A-Z0-9]+)$/,
    messageTemplate: /^([a-zA-Z0-9]+)$/,

    // sale
    sale: {
      group: {
        name: /^[a-zA-Z\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC 0-9 ]{3,}$/
      }
    },

    // treasury
    treasury: {
      bank: {
        shabaNumber: /^\d{24}$/,
        accountNumber: /^\d{6,15}$/
      },

      paymentGateway: {
        name: /^[\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC ()0-9]{5,50}$/,
        transferId: /^[a-zA-Z0-9_\/-]{4,70}$/
      },

      receive: {
        transactionId: /^[A-Za-z0-9_-]{5,70}$/
      }
    },

    //event
    event: {
      subject: /^[\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC \-\ (.)0-9]{5,50}$/,
      content: /^[#_*\[\],،:\>\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC\u0626 a-zA-Z \-\ \r\n(.)0-9]{5,400}$/,
    },

    // access group
    accessGroup: {
      name: /^[\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC ]{3,}$/
    },

    customEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

    shaba: {
      prefix: 'IR',
      pattern: /IR[0-9]{24}/
    },

    address: {
      name: /^[ \u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC]{3,}$/,
      city: /^[ \u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC]*$/,
      address: /^[/ \u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC\.\-\s\,0-9۰-۹٠-٩،,]{3,}$/,
      plaque: /^[0-9۰-۹٠-٩]{1,}$/,
      postalCode: /^[0-9۰-۹٠-٩]{10}$/
    }

  }
}
