export default {
  subject: {
  },

  errors: {
    users: {
      useral_user_id: 'شناسه شخص در یوزرال: {id}',
      empty_user_name: 'نام شخص وارد نشده است',
      empty_user_phone: 'شماره همراه شخص وارد نشده است',
      invalid_user_phone: 'شماره همراه شخص معتبر نیست',
      invalid_user_email: 'ایمیل شخص معتبر نیست'
    }
  }
}
