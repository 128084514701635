/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import {afterEach} from '@/router/afterEach'
import {beforeEach} from '@/router/beforeEach'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('../layouts/main/Main.vue'),
      meta: {requiresAuth: true},
      children: [
        // =============================================================================
        // HOME Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "home" */ '../views/admin/Home.vue'),
          meta: {
            pageTitle: 'داشبورد',
            pageTitleI18n: 'dashboard'
          }
        },

        // =============================================================================
        // PROFILE PAGES LAYOUTS
        // =============================================================================
        {
          path: '/profile',
          component: () => import('../views/admin/profile/index.vue'),
          children: [
            {
              path: '/',
              component: () => import(/* webpackChunkName: "profile" */ '../views/admin/profile/profile/userProfile.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.title',
                layoutStatus: 'profile'
                // rule: 'editor'
              },
              name: 'Profile'
            },
            {
              path: 'addresses',
              name: 'profileAddresses',
              component: () => import(/* webpackChunkName: "profileAddresses" */ '../views/admin/profile/addresses/profileAddresses.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.address.title'
                // rule: 'editor'
              }
            },
            {
              path: 'sessions',
              name: 'profileActiveSessions',
              component: () => import(/* webpackChunkName: "profileAddresses" */ '../views/admin/profile/activeSessions/profileActiveSessions.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.activeSessions.title'
                // rule: 'editor'
              }
            },
            {
              path: 'received-events',
              name: 'profileReceivedEvents',
              component: () => import(/* webpackChunkName: "profileReceivedEvents" */ '../views/admin/profile/events/profileEvents.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.events.receive.title'
                // rule: 'editor'
              }
            },
            {
              path: 'sent-events',
              name: 'profileSentEvents',
              component: () => import(/* webpackChunkName: "profileSentEvents" */ '../views/admin/profile/events/profileEvents.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.events.send.title'
                // rule: 'editor'
              }
            },
            {
              path: 'customize',
              name: 'profileCustomize',
              component: () => import(/* webpackChunkName: "profileCustomize" */ '../views/admin/profile/customize/profileCustomize.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.customize.title'
                // rule: 'editor'
              }
            },
            {
              path: 'edit',
              name: 'editProfile',
              component: () => import(/* webpackChunkName: "editProfile" */ '../views/admin/profile/edit/editProfile.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.edit.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // EVENTS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/events',
          component: () => import('../views/admin/events/index.vue'),
          children: [
            {
              path: '/',
              name: 'events',
              component: () => import(/* webpackChunkName: "events" */ '../views/admin/events/list/events.vue'),
              meta: {
                permission: 'event.show',
                pageTitle: '',
                pageTitleI18n: 'events.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // USERS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/users',
          component: () => import('../views/admin/users/index.vue'),
          children: [
            {
              path: '/',
              name: 'users',
              component: () => import(/* webpackChunkName: "users" */ '../views/admin/users/list/usersList.vue'),
              meta: {
                permission: 'user.show',
                pageTitle: 'اشخاص',
                pageTitleI18n: 'users.list.title'
                // rule: 'editor'
              }
            },
            {
              path: 'trash',
              name: 'usersTrash',
              component: () => import(/* webpackChunkName: "usersTrash" */ '../views/admin/users/trash/usersTrash.vue'),
              meta: {
                permission: 'user.delete',
                pageTitle: 'سطل زباله اشخاص',
                pageTitleI18n: 'users.trash.title'
                // rule: 'editor'
              }
            },
            {
              path: 'insert',
              name: 'insertUser',
              component: () => import(/* webpackChunkName: "insertUser" */ '../views/admin/users/insert/insertUser.vue'),
              meta: {
                permission: 'user.create',
                pageTitle: 'افزودن شخص',
                pageTitleI18n: 'users.insert.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id',
              component: () => import('../views/admin/users/user/index.vue'),
              children: [
                {
                  path: '/',
                  component: () => import(/* webpackChunkName: "user" */ '../views/admin/users/user/profile/userProfile'),
                  name: 'user',
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'شخص',
                    pageTitleI18n: 'users.profile.title',
                    layoutStatus: 'profile'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'edit',
                  name: 'editUser',
                  component: () => import(/* webpackChunkName: "editUser" */ '../views/admin/users/edit/editUser.vue'),
                  meta: {
                    permission: 'user.update',
                    pageTitle: ',ویرایش اطلاعات شخص',
                    pageTitleI18n: 'users.edit.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'transactions',
                  name: 'userTransactions',
                  component: () => import(/* webpackChunkName: "userTransactions" */ '../views/admin/users/user/transactions/userTransactions.vue'),
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'گردش مالی شخص',
                    pageTitleI18n: 'users.transactions.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'addresses',
                  name: 'userAddresses',
                  component: () => import(/* webpackChunkName: "userAddresses" */ '../views/admin/users/user/addresses/userAddresses.vue'),
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'شخص',
                    pageTitleI18n: 'users.addresses.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'score-history',
                  name: 'userScoreHistory',
                  component: () => import(/* webpackChunkName: "userScoreHistory" */ '../views/admin/users/user/scoreHistory/userScoreHistory.vue'),
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'شخص',
                    pageTitleI18n: 'users.scoreHistory.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'activities-log',
                  name: 'userActivitiesLog',
                  component: () => import(/* webpackChunkName: "userActivitiesLog" */ '../views/admin/users/user/activitiesLog/userActivitiesLog.vue'),
                  meta: {
                    permission: 'activity_logs.show',
                    pageTitle: 'لاگ های شخص',
                    pageTitleI18n: 'users.logs.title'
                    // rule: 'editor'
                  }
                }
              ]
            }
          ]
        },

        // =============================================================================
        // GAMES PAGES LAYOUTS
        // =============================================================================
        {
          path: '/matches',
          component: () => import('../views/admin/matches/index.vue'),
          children: [
            {
              path: '/',
              name: 'matches',
              component: () => import(/* webpackChunkName: "matches" */ '../views/admin/matches/list/matchesList.vue'),
              meta: {
                permission: 'game.show',
                pageTitle: 'بازی ها',
                pageTitleI18n: 'matches.list.title'
                // rule: 'editor'
              }
            },
            {
              path: 'trash',
              name: 'matchesTrash',
              component: () => import(/* webpackChunkName: "matchesTrash" */ '../views/admin/matches/trash/matchesTrash.vue'),
              meta: {
                permission: 'game.delete',
                pageTitle: 'سطل زباله مسابقه',
                pageTitleI18n: 'matches.trash.title'
                // rule: 'editor'
              }
            },
            {
              path: 'insert',
              name: 'insertMatch',
              component: () => import(/* webpackChunkName: "insertMatch" */ '../views/admin/matches/insert/insertMatch.vue'),
              meta: {
                permission: 'game.create',
                pageTitle: 'افزودن بازی',
                pageTitleI18n: 'matches.insert.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id',
              component: () => import('../views/admin/matches/index.vue'),
              children: [
                {
                  path: '/',
                  component: () => import(/* webpackChunkName: "match" */ '../views/admin/matches/profile/matchProfile.vue'),
                  name: 'match',
                  meta: {
                    permission: 'game.show',
                    pageTitle: 'مسابقه',
                    pageTitleI18n: 'matches.profile.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'edit',
                  name: 'editMatch',
                  component: () => import(/* webpackChunkName: "editMatch" */ '../views/admin/matches/edit/editMatch.vue'),
                  meta: {
                    permission: 'game.update',
                    pageTitle: 'ویرایش اطلاعات مسابقه',
                    pageTitleI18n: 'matches.edit.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'activities-log',
                  name: 'matchActivitiesLog',
                  component: () => import(/* webpackChunkName: "matchActivitiesLog" */ '../views/admin/matches/activitiesLog/matchActivitiesLog.vue'),
                  meta: {
                    permission: 'activity_logs.show',
                    pageTitle: 'لاگ های مسابقه',
                    pageTitleI18n: 'matches.logs.title'
                    // rule: 'editor'
                  }
                }
              ]
            }
          ]
        },

        // =============================================================================
        // SETTING PAGES LAYOUTS
        // =============================================================================
        {
          path: '/settings',
          component: () => import('../views/admin/settings/index.vue'),
          children: [
            {
              path: '/',
              name: 'Settings',
              component: () => import(/* webpackChunkName: "Settings" */ '../views/admin/settings/setting/settingMenu.vue'),
              meta: {
                pageTitle: 'تنظیمات',
                pageTitleI18n: 'setting.title',
                layoutStatus: 'profile'
                // rule: 'editor'
              }
            },
            {
              path: 'company-info',
              name: 'companyInfoSetting',
              component: () => import(/* webpackChunkName: "companyInfoSetting" */ '../views/admin/settings/companyInfo/companyInfoSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.companyInfo.title'
                // rule: 'editor'
              }
            },
            {
              path: 'backup',
              name: 'backup',
              component: () => import(/* webpackChunkName: "backup" */ '../views/admin/settings/backup/backup.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'backup.title'
                // rule: 'editor'
              }
            },
            {
              path: 'roles',
              component: () => import(/* webpackChunkName: "access" */ '../views/admin/settings/access/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'accessGroupsList',
                  component: () => import(/* webpackChunkName: "accessGroupsList" */ '../views/admin/settings/access/list/accessGroupsList.vue'),
                  meta: {
                    permission: 'role.show',
                    pageTitle: '',
                    pageTitleI18n: 'setting.accessGroup.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'languages',
              name: 'languagesSetting',
              component: () => import(/* webpackChunkName: "languagesSetting" */ '../views/admin/settings/locale/LocaleSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.Locale.title',
                permission: 'setting.show'
                // rule: 'editor'
              }
            },
            {
              path: 'users',
              name: 'usersSetting',
              component: () => import(/* webpackChunkName: "usersSetting" */ '../views/admin/settings/users/usersSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.users.title'
                // rule: 'editor'
              }
            },
            {
              path: 'security',
              name: 'securitySetting',
              component: () => import(/* webpackChunkName: "securitySetting" */ '../views/admin/settings/security/securitySetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.security.title',
                permission: 'setting.show'
                // rule: 'editor'
              }
            },
            {
              path: 'match',
              name: 'matchSetting',
              component: () => import(/* webpackChunkName: "matchSetting" */ '../views/admin/settings/match/matchSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.match.title',
                permission: 'setting.show'
                // rule: 'editor'
              }
            },
            {
              path: 'customizing',
              name: 'customizeSetting',
              component: () => import(/* webpackChunkName: "customizeSetting" */ '../views/admin/settings/customize/customizeSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.customize.title',
                permission: 'setting.show'
                // rule: 'editor'
              }
            },
            {
              path: 'advanced',
              name: 'advancedSetting',
              component: () => import(/* webpackChunkName: "advancedSetting" */ '../views/admin/settings/advanced/advancedSetting.vue'),
              meta: {
                pageTitle: 'تنظیمات پیشرفته',
                pageTitleI18n: ''
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // REPORTS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/reports',
          component: () => import('../views/admin/reports/index'),
          children: [
            {
              path: 'activities-log',
              name: 'activitiesLog',
              component: () => import(/* webpackChunkName: "activitiesLog" */ '../views/admin/reports/activitiesLog/usersActivitiesLog.vue'),
              meta: {
                permission: 'activity_logs.show',
                pageTitle: '',
                pageTitleI18n: 'reports.logs.list.title'
                // rule: 'editor'
              }
            }
          ]
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'page-login',
          component: () => import(/* webpackChunkName: "page-login" */ '@/views/pages/Login.vue')
        },
        {
          path: '/live-ranking',
          name: 'liveRanking',
          meta: {requiresAuth: true},
          component: () => import(/* webpackChunkName: "live-ranking" */ '@/views/admin/ranking/UserRanking.vue')
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import(/* webpackChunkName: "page-error-404" */ '@/views/pages/Error404.vue')
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(afterEach)

export default router

router.beforeEach(beforeEach)
