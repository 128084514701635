export default {
  title: 'مسابقات',

  labels: {
    team1: 'تیم 1',
    team2: 'تیم 2'
  },

  table: {
    header: {
      row: 'ردیف',
      name: 'نام بازیکن',
      winner: 'برنده',
      date: 'تاریخ',
      deleteAction: 'حذف',
      restoreAction: 'بازگردانی'
    }
  },

  notifications: {
    insert: {
      success: 'مسابقه با موفقیت ثبت شد',
      error: 'افزودن مسابقه با خطا همراه شد'
    },

    edit: {
      success: 'مسابقه با موفقیت بروزرسانی شد',
      error: 'بروزرسانی مسابقه با خطا همراه شد'
    },

    delete: {
      success: 'مسابقه با موفقیت حذف شد',
      error: 'حذف مسابقه با خطا همراه شد'
    },

    restore: {
      success: 'مسابقه با موفقیت بازگردانی شد',
      error: 'بازگردانی مسابقه با خطا همراه شد'
    },

    parseError: {
      score: 'امتیازات وارد شده معتبر نیست',
      teamLength: 'اعضای تیم تکمیل نشده است',
      playerExist: 'این بازیکن قبلا انتخاب شده است'
    }
  },

  confirmations: {
    delete: {
      title: 'تاییدیه حذف مسابقه',
      body: 'آیا از حذف این مسابقه اطمینان دارید؟'
    }
  },

  list: {
    title: 'مسابقات'
  },

  trash: {
    title: 'مسابقات حذف شده'
  },

  profile: {
    title: 'پروفایل مسابقه'
  },

  insert: {
    title: 'افزودن مسابقه'
  },

  edit: {
    title: 'ویرایش مسابقه'
  },

  logs: {
    title: 'لاگ های مسابقه'
  }
}