export default {
  subject: {
  },

  errors: {
    users: {
      useral_user_id: 'Useral user id: {id}',
      empty_user_name: 'Empty user name',
      empty_user_phone: 'Empty user phone',
      invalid_user_phone: 'Invalid user phone',
      invalid_user_email: 'Invalid user email'
    }
  }
}
