export default {
  title: 'Settings',

  importantData: {
    balance: 'inventory',
    employeeCount: 'Number of employees'
  },

  buttons: {
    save: 'save changes',
    reset: 'undo changes',
    removeLogo: 'remove logo',
    uploadLogo: 'Load Logo'
  },

  companyInfo: {
    title: 'Company Info',

    labels: {
      generalInfo: 'General info',
      telInfo: 'Tell info',
      financialInfo: 'Financial info',
      uploadLogo: 'Upload logo',
      preview: 'Preview:',
      name: 'Company name',
      managerName: 'Manager full name',
      country: 'Country',
      province: 'State',
      city: 'City',
      address: 'Address',
      phoneNumber: 'Phone number',
      whatsAppSupport: 'WhatsApp support',
      managerPhoneNumber: 'Manager phone number'
    },

    description: {
      logoRule: 'Authorized JPG, JPEG or PNG formats. Maximum logo size 800 KB ',
      generalInfo: 'This box displays the general information of the company.',
      telInfo: 'In this box the company contact information is displayed.',
      financialInfo: 'This box displays the companys financial information.'
    },

    validators: {
      imageSize: 'The selected file size is too large',
      fileFormat: 'The selected file format is not allowed',
      name: 'Name is not valid'
    },

    notifications: {
      logo: {
        upload: {
          success: 'Logo uploaded successfully'
        },
        delete: {
          success: 'Logo removed successfully'
        }
      },

      edit: {
        success: 'Store information updated successfully'
      }
    }
  },

  accessGroup: {
    title: 'User Groups',

    notifications: {
      insert: {
        success: 'User group added successfully',
        error: 'Add user group failed'
      },

      edit: {
        success: 'User group successfully edited',
        error: 'User group editing encountered an error'
      },

      delete: {
        success: 'User group successfully deleted',
        error: 'The user group could not be found.',
        cantDelete: 'The user group could not be deleted because it has a subgroup.'
      },

      deleteUser: {
        success: 'User successfully deleted from group',
        error: 'Delete user from group has error'
      }
    },

    confirmations: {
      delete: {
        title: 'Delete User Group',
        body: 'Are you sure you want to delete {name}?'
      },

      deleteUser: {
        title: 'Delete User From User Group',
        body: 'Are you sure you want to delete {name} from this group?'
      }
    },

    insert: {
      title: 'Add a new user group',

      labels: {
        name: 'User Group Title',
        allowedActiveSession: 'Active sessions allowed',
        access: 'access',
        insert: 'Add',
        inMenu: 'Menu',

        accessList: {
          manager: 'General Manager Access',

          users: {
            totalAccess: 'Full access of persons',
            show: 'View People',
            insert: 'Add person',
            update: 'full edit',
            delete: 'Temporarily delete person',
            setRole: 'Assign a role to a person'
          },

          roles: 'Full access to roles',
          treasury: {
            title: 'Full access to the Treasury',

            bank: 'full access to banks',
            cash: 'Full access to funds'
          },

          reports: {
            title: 'Full access to reports',
            logs: 'access logs'
          },

          delete: {
            title: 'Permanent removal',

            user: 'People',
            banks: 'Banks',
            cash: 'Cashiers'
          }
        }
      },

      validators: {
        name: 'Group name is not entered correctly',
        nameCantEmpty: 'Name could not be empty',
        activeSessionCount: 'Active sessions should be less than equal 5',
        permissions: 'No access selected'
      }
    },

    edit: {
      title: 'Edit User Group',

      validators: {
        name: 'Group name is not entered correctly',
        nameCantEmpty: 'Name could not be empty',
        activeSessionCount: 'Active sessions should be less than equal 5',
        permissions: 'No access selected'
      }
    },

    insertUserToAccessGroup: {
      selectUser: 'Select User',
      usersList: 'Users List',

      notifications: {
        success: 'access group successfully set to user',
        error: 'set access group to user failed',
        user_email_not_ser: 'no email set for this user',
        noUserSelected: 'no user selected'
      }
    },

    table: {

      header: {
        row: 'Row',
        name: 'Access Title'
      }
    },

    actions: {
      insert: 'Add',
      save: 'Register'
    }
  },

  users: {
    title: 'People Settings',

    labels: {
      gender: 'gender mandatory'
    }
  },

  security: {
    title: 'Security',

    labels: {
      disActive: 'Compulsory exit period if not active',
      minute: 'minute'
    }
  },

  customize: {
    title: 'System Personalization',

    labels: {
      theme: 'Select the desired theme'
    }
  },

  General: {
    title: 'General',
    logo: {
      rule: 'Authorized JPG, JPEG or PNG formats. Maximum logo size 800 KB '
    },
    club: {
      code: 'company code',
      name: 'company name',
      managerName: 'managers first and last name',
      address: 'Address',
      phoneNumber: 'Mobile Number',
      managerPhoneNumber: 'CEO Mobile Number'
    }
  },

  Locale: {
    title: 'Languages',
    language: 'Language',
    basicSetting: 'Basic settings',

    labels: {
      basicSetting: 'Base setting',
      defaultLanguage: 'Club default language',
      defaultCurrency: 'Club default currency',
      region: 'Club default region',
      defaultCountryCode: 'Club default country code'
    },

    currency: {
      IRT: 'IRT',
      IRR: 'IRR',
      USD: 'USD',
      AED: 'AED',
      other: 'other'
    },

    notifications: {
      edit: {
        success: 'Locale setting was successfully edited',
        error: 'Edit locale setting has error'
      }
    }
  },

  match: {
    title: 'Match',

    labels: {
      point: 'Match Point',
      winnerPoint: 'Winner Point'
    },

    notifications: {
      edit: {
        success: 'Match setting successfully updated',
        error: 'Update match setting has error'
      }
    }
  }
}
