export default {
  list: {
    table: {
      header: {
        rowNumber: 'ردیف',
        user: 'کاربر',
        description: 'توضیحات',
        date: 'تاریخ'
      }
    },

    dialogs: {
      title: 'توضیحات',

      noChange: 'فیلدی تغییری داده نشده است'
    }
  },

  login: 'کاربر وارد سامانه شد',
  logout: 'کاربر از سامانه خارج شد',
  register: 'کاربر در سامانه ثبت نام شد',
  reset_app: 'سامانه بازنشانی شد',

  user: {
    store: 'اطلاعات کاربر ایجاد شد',
    update: 'اطلاعات کاربر به روزرسانی شد',
    soft_destroy: 'اطلاعات کاربر حذف موقت شد',
    restore: 'اطلاعات کاربر بازگردانی شد',
    destroy: 'اطلاعات کاربر حذف شد',
    set_role: 'به کاربر نقش تخصیص داده شد',
    set_max_debt: 'سقف بدهکاری شخص به روز رسانی شد'
  },

  user_address: {
    store: 'آدرس جدید به کاربر اضافه شد',
    update: 'آدرس کاربر به روز شد',
    destroy: 'آدرس کاربر حذف شد'
  },

  game: {
    store: 'اطلاعات مسابقه ایجاد شد',
    update: 'اطلاعات مسابقه به روزرسانی شد',
    soft_destroy: 'اطلاعات مسابقه حذف موقت شد',
    restore: 'اطلاعات مسابقه بازگردانی شد',
    destroy: 'اطلاعات مسابقه حذف شد'
  },

  role: {
    store: 'گروه دسترسی ایجاد شد',
    update: 'گروه دسترسی بروزرسانی شد',
    destroy: 'گروه دسترسی حذف شد'
  },

  changeFields: {
    store: {
      user: {
        name: 'نام «{new}» ثبت شد.',
        family: 'نام خانوادگی «{new}» ثبت شد.',
        gender: 'جنسیت «{new}» ثبت شد.',
        avatar: 'عکس پروفایل کاربر ثبت شد',
        company: 'شرکت «{new}» ثبت شد.',
        phone_number: 'شماره تلفن «{new}» ثبت شد.',
        email: 'ایمیل «{new}» ثبت شد.',
        max_debt: 'سقف بدهکاری «{new}» ثبت شد.',
      },

      user_address: {
        addresses: {
          address: 'آدرس «{new}» ثبت شد.',
          city: 'شهر «{new}» ثبت شد.',
          province: 'استان «{new}» ثبت شد.',
          post_code: 'کد پستی «{new}» ثبت شد.',
          receiver_call_number: 'تلفن گیرنده «{new}» ثبت شد.',
          receiver_family: 'نام خانوادگی گیرنده «{new}» ثبت شد.',
          receiver_name: 'نام گیرنده «{new}» ثبت شد.',
          title: 'عنوان «{new}» ثبت شد.'
        }
      },
      game: {
        date: 'تاریخ «{new}» ثبت شد.',
        difference: 'تفاضل امتیاز «{new}» ثبت شد.',
        winner: 'برنده «{new}» ثبت شد.',
        players: {
          group: 'تیم «{new}» ثبت شد.',
          user: 'بازیکن «{new}» ثبت شد.',
        },
        scores: {
          first_group: 'امتیاز تیم اول «{new}» ثبت شد.',
          second_group: 'امتیاز تیم دوم «{new}» ثبت شد.',
        }
      },

      role: {
        name: 'نام «{new}» ثبت شد.',
        parent: 'والد «{new}» ثبت شد.',
        allowed_active_sessions: 'تعداد نشست های فعال «{new}» ثبت شد.',
        permissions: {
          id: 'شناسه دسترسی «{new}» ثبت شد.',
          name: 'نام دسترسی «{new}» ثبت شد.',
          slug: 'نامک دسترسی «{new}» ثبت شد.'
        }
      }
    },

    update: {
      user: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        family: 'نام خانوادگی از «{old}» به «{new}» بروزرسانی شد.',
        gender: 'جنسیت از «{old}» به «{new}» بروزرسانی شد.',
        avatar: 'عکس پروفایل کاربر بروزرسانی شد',
        company: 'شرکت از «{old}» به «{new}» بروزرسانی شد.',
        phone_number: 'شماره تلفن از «{old}» به «{new}» بروزرسانی شد.',
        email: 'ایمیل از «{old}» به «{new}» بروزرسانی شد.',
        max_debt: 'سقف بدهکاری از «{old}» به «{new}» بروزرسانی شد.',
      },

      user_address: {
        addresses: {
          address: 'آدرس از «{old}» به «{new}» بروزرسانی شد.',
          city: 'شهر از «{old}» به «{new}» بروزرسانی شد.',
          province: 'استان از «{old}» به «{new}» بروزرسانی شد.',
          post_code: 'کد پستی از «{old}» به «{new}» بروزرسانی شد.',
          receiver_call_number: 'تلفن گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_family: 'نام خانوادگی گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_name: 'نام گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          title: 'عنوان از «{old}» به «{new}» بروزرسانی شد.'
        }
      },

      game: {
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.',
        difference: 'تفاضل امتیاز از «{old}» به «{new}» بروزرسانی شد.',
        winner: 'برنده از «{old}» به «{new}» بروزرسانی شد.',
        players: {
          group: 'تیم از «{old}» به «{new}» بروزرسانی شد.',
          user: 'بازیکن از «{old}» به «{new}» بروزرسانی شد.'
        },
        scores: {
          first_group: 'امتیاز تیم اول از «{old}» به «{new}» بروزرسانی شد.',
          second_group: 'امتیاز تیم دوم از «{old}» به «{new}» بروزرسانی شد.',
        },
      },

      role: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        parent: 'والد از «{old}» به «{new}» بروزرسانی شد.',
        allowed_active_sessions: 'تعداد نشست های فعال از «{old}» به «{new}» بروزرسانی شد.',
        permissions: {
          id: 'شناسه دسترسی از «{old}» به «{new}» بروزرسانی شد.',
          name: 'نام دسترسی از «{old}» به «{new}» بروزرسانی شد.',
          slug: 'نامک دسترسی از «{old}» به «{new}» بروزرسانی شد.'
        }
      }
    }
  }
}
