export default {
  list: {
    table: {
      header: {
        rowNumber: 'Row',
        user: 'User',
        description: 'Description',
        date: 'Date'
      }
    },

    dialogs: {
      title: 'Descriptions',

      noChange: 'No field change'
    }
  },

  login: 'User login',
  logout: 'User logout',
  register: 'User register',
  reset_app: 'Reset app',

  user: {
    store: 'User info created',
    update: 'User info updated',
    soft_destroy: 'User info temporary deleted',
    restore: 'User info restored',
    destroy: 'User info destroyed',
    set_role: 'Set role to user',
    set_max_debt: 'Set max debt to user'
  },

  user_address: {
    store: 'User address created',
    update: 'User address updated',
    destroy: 'User address deleted'
  },

  game: {
    store: 'Match info created',
    update: 'Match info updated',
    soft_destroy: 'Match info temporary deleted',
    restore: 'Match info restored',
    destroy: 'Match info destroyed'
  },

  role: {
    store: 'Role created',
    update: 'Role info updated',
    destroy: 'Role info deleted'
  },

  changeFields: {
    store: {
      user: {
        name: 'Name set to "{new}".',
        family: 'Family set to "{new}".',
        gender: 'Gender set to "{new}".',
        avatar: 'User Avatar stored',
        company: 'Company set to "{new}".',
        phone_number: 'Phone number set to "{new}".',
        email: 'Email set to "{new}".',
        max_debt: 'Max debt set to "{new}".'
      },

      user_address: {
        addresses: {
          address: 'User address set to "{new}".',
          city: 'City set to "{new}".',
          province: 'Province set to "{new}".',
          post_code: 'Post code set to "{new}".',
          receiver_call_number: 'Receiver phone number set to "{new}".',
          receiver_family: 'Receiver family set to "{new}".',
          receiver_name: 'Receiver name set to "{new}".',
          title: 'Title set to "{new}".'
        }
      },

      game: {
        date: 'Date set to "{new}".',
        difference: 'Difference set to "{new}".',
        winner: 'Winner set to "team {new}".',
        players: {
          group: 'Player group set to "{new}".',
          user: 'Player name set to "{new}".',
        },
        scores: {
          first_group: 'Score of team 1 set to "{new}".',
          second_group: 'Score of team 2 set to "{new}".',
        }
      },

      role: {
        name: 'Name set to "{new}".',
        parent: 'Parent set to "{new}".',
        allowed_active_sessions: 'Maximum active sessions set to "{new}".',
        permissions: {
          id: 'Permission id set to "{new}".',
          name: 'Permission name set to "{new}".',
          slug: 'Permission slug set to "{new}".'
        }
      }
    },

    update: {
      user: {
        name: 'Name updated from "{old}" to "{new}".',
        family: 'Family updated from "{old}" to "{new}".',
        gender: 'Gender updated from "{old}" to "{new}".',
        avatar: 'User Avatar updated',
        company: 'Company updated from "{old}" to "{new}".',
        phone_number: 'Phone number updated from "{old}" to "{new}".',
        email: 'Email updated from "{old}" to "{new}".',
        max_debt: 'Max debt updated from "{old}" to "{new}".'
      },

      user_address: {
        addresses: {
          address: 'User address updated from "{old}" to "{new}".',
          city: 'City updated from "{old}" to "{new}".',
          province: 'Province updated from "{old}" to "{new}".',
          post_code: 'Post code updated from "{old}" to "{new}".',
          receiver_call_number: 'Receiver phone number updated from "{old}" to "{new}".',
          receiver_family: 'Receiver family updated from "{old}" to "{new}".',
          receiver_name: 'Receiver name updated from "{old}" to "{new}".',
          title: 'Title updated from "{old}" to "{new}".'
        }
      },
      game: {
        date: 'Date updated from "{old}" to "{new}".',
        difference: 'Difference updated from "{old}" to "{new}".',
        winner: 'Winner updated from "team {old}" to "team {new}".',
        players: {
          group: 'Player group updated from "{old}" to "{new}".',
          user: 'Player name updated from "{old}" to "{new}".',
        },
        scores: {
          first_group: 'Score of team 1 updated from "{old}" to "{new}".',
          second_group: 'Score of team 2 updated from "{old}" to "{new}".'
        }
      },

      role: {
        name: 'Name updated from "{old}" to "{new}".',
        parent: 'Parent updated from "{old}" to "{new}".',
        allowed_active_sessions: 'Maximum active sessions updated from "{old}" to "{new}".',
        permissions: {
          id: 'Permission id updated from "{old}" to "{new}".',
          name: 'Permission name updated from "{old}" to "{new}".',
          slug: 'Permission slug updated from "{old}" to "{new}".'
        }
      }
    }
  }
}
