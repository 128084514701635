export default {
  title: 'تنظیمات',

  importantData: {
    balance: 'موجودی',
    employeeCount: 'تعداد کارمندان'
  },

  buttons: {
    save: 'ذخیره تغییرات',
    reset: 'برگرداندن تغییرات',
    removeLogo: 'حذف لوگو',
    uploadLogo: 'بارگزاری لوگو'
  },

  companyInfo: {
    title: 'اطلاعات شرکت',

    labels: {
      generalInfo: 'اطلاعات عمومی',
      telInfo: 'اطلاعات تماس',
      financialInfo: 'اطلاعات مالی',
      uploadLogo: 'آپلود لوگو',
      preview: 'پیشنمایش:',
      name: 'نام شرکت',
      managerName: 'نام و نام خانوادگی مدیر',
      country: 'کشور',
      province: 'استان',
      city: 'شهر',
      address: 'آدرس',
      phoneNumber: 'شماره تماس',
      whatsAppSupport: 'پشتیبانی واتساپ',
      managerPhoneNumber: 'شماره تماس مدیر'
    },

    description: {
      logoRule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم لوگو 800 کیلوبایت',
      generalInfo: 'در این باکس اطلاعات عمومی شرکت نمایش داده می شود.',
      telInfo: 'در این باکس اطلاعات تماس شرکت نمایش داده می شود.',
      financialInfo: 'در این باکس اطلاعات مالی شرکت نمایش داده می شود.'
    },

    validators: {
      imageSize: 'حجم فایل انتخاب شده بیش از حد مجاز است',
      fileFormat: 'فرمت فایل انتخاب شده مجاز نیست',
      name: 'نام وارد شده معتبر نمیباشد'
    },

    notifications: {
      logo: {
        upload: {
          success: 'لوگو با موفقیت آپلود شد'
        },
        delete: {
          success: 'لوگو با موفقیت حذف شد'
        }
      },

      edit: {
        success: 'اطلاعات فروشگاه با موفقیت بروز شد'
      }
    }
  },

  accessGroup: {
    title: 'گروه های کاربری',

    notifications: {
      insert: {
        success: 'گروه کاربری با موفقیت اضافه شد',
        error: 'افزودن گروه کاربری با خطا همراه شد'
      },

      edit: {
        success: 'گروه کاربری با موفقیت ویرایش شد',
        error: 'ویرایش گروه کاربری با خطا همراه شد'
      },

      delete: {
        success: 'گروه کاربری با موفقیت حذف شد',
        error: 'گروه کاربری مورد نظر یافت نشد.',
        cantDelete: 'گروه کاربری مورد نظر به دلیل داشتن زیر گروه, قابل حذف نمی باشد.'
      },

      deleteUser: {
        success: 'کاربر با موفقت از گروه کاربری حذف شد',
        error: 'حذف کاربر از گروه کاربری با خطا همراه شد'
      }
    },

    confirmations: {
      delete: {
        title: 'حذف گروه کاربری',
        body: 'آیا از حذف  {name} اطمینان دارید؟'
      },

      deleteUser: {
        title: 'حذف کاربر از گروه کاربری',
        body: 'آیا از حذف  {name} از این گروه کاربری اطمینان دارید؟'
      }
    },

    insert: {
      title: 'افزودن گروه کاربری جدید',

      labels: {
        name: 'عنوان گروه کاربری ',
        allowedActiveSession: 'تعداد نشست های فعال مجاز',
        access: 'دسترسی',
        insert: 'افزودن',
        inMenu: 'منو',

        accessList: {
          manager: 'دسترسی مدیرکل',

          users: {
            totalAccess: 'دسترسی کامل اشخاص',
            show: 'مشاهده اشخاص',
            insert: 'افزودن شخص',
            update: 'ویرایش کامل',
            delete: 'حذف موقت  شخص',
            setRole: 'اختصاص نقش به شخص'
          },

          roles: 'دسترسی کامل نقش ها',
          treasury: {
            title: 'دسترسی کامل خزانه داری',

            bank: 'دسترسی کامل بانک ها',
            cash: 'دسترسی کامل صندوق ها'
          },

          reports: {
            title: 'دسترسی کامل گزارشات',
            logs: 'دسترسی لاگ ها'
          },

          delete: {
            title: 'حذف دائم',

            user: 'اشخاص',
            banks: 'بانک ها',
            cash: 'صندوق ها'
          }
        }
      },

      validators: {
        name: 'نام گروه به صورت صحیح وارد نشده است',
        nameCantEmpty: 'نام نمی تواند  خالی باشد',
        activeSessionCount: 'تعداد سشن فعال بایستی کمتر و یا مساوی 5 باشد',
        permissions: 'هیچ دسترسی انتخاب نشده است'
      }
    },

    edit: {
      title: 'ویرایش گروه کاربری',

      validators: {
        name: 'نام گروه به صورت صحیح وارد نشده است',
        nameCantEmpty: 'نام نمی تواند  خالی باشد',
        activeSessionCount: 'تعداد سشن فعال بایستی کمتر و یا مساوی 5 باشد',
        permissions: 'هیچ دسترسی انتخاب نشده است'
      }
    },

    insertUserToAccessGroup: {
      selectUser: 'انتخاب کاربر',
      usersList: 'لیست کاربران',

      notifications: {
        success: 'گروه دسترسی شخص مورد با موفقیت بروزسانی شد',
        error: 'بروزرسانی گروه دسترسی شخص مورد نظر با خطا همراه شد',
        user_email_not_ser: 'برای این شخص ایمیل ثبت نشده است',
        noUserSelected: 'هیچ شخصی انتخاب نشده است'
      }
    },

    table: {

      header: {
        row: 'ردیف',
        name: 'عنوان دسترسی'
      }
    },

    actions: {
      insert: 'افزودن',
      save: 'ثبت'
    }
  },

  users: {
    title: 'تنظیمات اشخاص',

    labels: {
      gender: 'اجباری بودن جنسیت'
    }
  },

  security: {
    title: 'امنیت',

    labels: {
      disActive: 'مدت زمان خروج اجباری در صورت نداشتن فعالیت',
      minute: 'دقیقه'
    }
  },

  customize: {
    title: 'شخصی سازی سامانه',

    labels: {
      theme: 'انتخاب تم مورد نظر'
    }
  },

  General: {
    title: 'عمومی',
    logo: {
      rule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم لوگو 800 کیلوبایت'
    },
    club: {
      code: 'کد شرکت',
      name: 'نام شرکت',
      managerName: 'نام و نام خانوادگی مدیر',
      address: 'آدرس',
      phoneNumber: 'شماره تماس',
      managerPhoneNumber: 'شماره تماس مدیر'
    }
  },

  Locale: {
    title: 'زبان ها',
    language: 'زبان',
    basicSetting: 'تنظیمات پایه',

    labels: {
      basicSetting: 'تنظیمات پایه',
      defaultLanguage: 'زبان پیشفرض شرکت',
      defaultCurrency: 'واحد پولی پیشفرض شرکت',
      region: 'منطقه پیش فرض شرکت',
      defaultCountryCode: 'پیش شماره پیشفرض شرکت'
    },

    currency: {
      IRT: 'تومان',
      IRR: 'ریال',
      USD: 'دلار',
      AED: 'درهم',
      other: 'سایر'
    },

    notifications: {
      edit: {
        success: 'اطلاعات زبان با موفقیت به روز شد.',
        error: 'بروزرسانی اطلاعات زبان با خطا همراه شد.'
      }
    }
  },

  match: {
    title: 'مسابقات',

    labels: {
      point: 'امتیاز بازی',
      winnerPoint: 'امتیاز برنده'
    },

    notifications: {
      edit: {
        success: 'تنظیمات مسابقه با موفقیت بروزرسانی شد',
        error: 'بروزرسانی تنظیمات مسابقه با خطا همراه شد'
      }
    }
  }
}
