/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

// vue meta
import vueMeta from 'vue-meta'

Vue.use(vueMeta)

// axios
import axios from './axios.js'

Vue.prototype.$http = axios

// export to excel
import excel from 'vue-excel-export'

Vue.use(excel)


// i18n
import i18n from './i18n/i18n'

// Filters
import './filters/filters.js'


// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router/router'


// Vuex Store
import store from './store/store'

// Environment
import env from './environment/environment'

Vue.use(env)

// Vuejs - Vue wrapper for hammerjs
import {VueHammer} from 'vue2-hammer'

Vue.use(VueHammer)

// pusher
import Echo from "laravel-echo"

/*require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'saeed123',
  wsHost: 'core.useral.com',
  wsPort: 6001,
  wssPort: 6001,
  forceTLS: true,
  disableStats: true,
  authEndpoint: 'https://core.useral.com/broadcasting/auth',
});*/


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Font Awesome icon
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// Feather font icon
require('./assets/css/iconfont.css')

// opensans font
require('./assets/css/opensans.css')

// iransans font
require('./assets/css/iransans.css')

// helvetica font
require('./assets/css/helvetica.css')

// roboto font
require('./assets/css/roboto.css')

// draggable dynamic table
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
Vue.component('DraggableDynamicTable', DraggableDynamicTable)

// crud tree
import CrudTree from '@/components/crudTree/crudTree'
Vue.component('CrudTree', CrudTree)

// import vue directives
import './directives'


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

// import local validators
require('./validators/validator')
require('./localeValidators/localeValidator')


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
