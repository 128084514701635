export default {
  regex: {
    hour: /^((([0-1]?[0-9]|2[0-3]))|24)$/,
    time: /^((([0-1]?[0-9]|2[0-3]):[0-5][0-9])|24:00)$/,
    date: /^\d{4}[\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/,
    dateTime: /^\d{4}[\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[ ]((([0-1]?[0-9]|2[0-3]):[0-5][0-9])|24:00)$/,
  },

  moment: {
    year: 'YYYY',
    month: 'MM',
    yearMonth: 'YY-MM',
    second: 'ss',
    time: 'HH:mm',
    date: 'YYYY-MM-DD',
    dateHour: 'YYYY-M-D HH',
    dateTime: 'YYYY-MM-DD HH:mm',
    dateWithWeek: 'dddd D MMMM',
    fullDateWithWeek: 'dddd D MMMM YYYY',
    fullDateTime: 'YYYY-M-D HH:mm:ss',

    weekDay: {
      1: 'Saturday',
      2: 'Sunday',
      3: 'Monday',
      4: 'Tuesday',
      5: 'Wednesday',
      6: 'Thursday',
      7: 'Friday'
    }
  }
}
