import Vue from 'vue'
import store from '../store/store'

function setValidator (selector = '') {
  let result = ''
  let validatorData = {}
  try {
    const validatorFile = store.state.locale.validator
    validatorData = require(`./${validatorFile}`).default
  } catch (e) {
    validatorData = require('./fa').default
  }

  if (selector) {
    result = validatorData
    let hasError = false
    selector.split('.').forEach((validator) => {
      if (result.hasOwnProperty(validator)) {
        result = result[validator]
      } else {
        hasError = true
        return false
      }
    })

    if (typeof result === 'object' && Object.keys(result).length > 0) {
      hasError = true
    }

    if (!hasError) {
      return  result
    } else {
      return selector
    }
  } else {
    return selector
  }
}

Vue.prototype.$validator = setValidator
